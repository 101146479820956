#consulta_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.consulta_middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.consulta_middle .consulta_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.consulta_middle .consulta_text p {
    color: #000;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
}

.consulta_middle .consulta_text input {
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}

.consulta_middle .consulta_text .consulta_input {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 0px 2px 2px rgba(45, 45, 45, 0.3);
    text-align: center;
}

.consulta_middle .consulta_text .consulta_input:focus {
    outline: none;
    box-shadow: 0px 0px 2px 2px rgba(45, 45, 45, 0.3);
}

.consulta_middle .consulta_text .consulta_error {
    color: #d20303;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
}

.consulta_middle .consulta_text .consulta_button {
    color: white;
    background: rgb(255, 139, 55);
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 2px 2px rgba(45, 45, 45, 0.3);
    width: 100%;
    max-width: 300px;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 768px) {
    #consulta_page {
        height: auto;
        min-height: 100vh;
        padding: 10px;
    }

    .consulta_middle {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .consulta_middle .consulta_text {
        max-width: 90%;
    }

    .consulta_middle .consulta_text .consulta_input, 
    .consulta_middle .consulta_text .consulta_button {
        width: 100%;
    }

    .consulta_middle .consulta_text p {
        font-size: 14px;
    }
}